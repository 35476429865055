<template>
  <v-list-item 
		dense
		:draggable="drag && !disabled"
    @dragstart="(!drag && disabled) || startDrag($event, value)"
		:class="{ 'cursor-grab' : drag && !disabled, 'unidad-disabled' : disabled }"
	>
    <v-list-item-title>
      {{ value.nombre_completo }}
      <v-chip :color="value.color" class="ml-1" x-small outlined label>
        {{ value.nombreCategoria }}
      </v-chip>
    </v-list-item-title>
    <small class="text-no-wrap text--disabled">{{ value.desc }}</small>
    <div v-if="!drag && !noEstado">
      <v-speed-dial v-model="openBtn" direction="right">
        <template v-slot:activator>
          <v-btn class="ml-3" v-model="openBtn" icon>
            <v-icon small v-if="openBtn"> mdi-close </v-icon>
            <v-icon :color="estadoActual.color" small v-else>
              {{ estadoActual.icon }}
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip
          v-for="estado in estados"
          :key="estado.idEstadoUnidadesEquipo"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              :color="estado.color"
              @click.stop="cambiarEstado(estado)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ estado.icon }}</v-icon>
            </v-btn>
          </template>
          {{ estado.nombre }}
        </v-tooltip>
				<v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="grey"
              @click.stop="cambiarEstado(null)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-help</v-icon>
            </v-btn>
          </template>
          Sin definir
        </v-tooltip>
      </v-speed-dial>
    </div>
    <div v-if="drag" class="d-flex flex-column ml-2">
      <v-icon>mdi-dots-horizontal</v-icon>
      <v-icon class="mt-n4">mdi-dots-horizontal</v-icon>
    </div>
    <div v-if="value.used && showRepo" class="d-flex justify-center align-center">
      <v-btn 
      color="primary"
      fab
      x-small
      @click="changeUsed"
      style="z-index:1000"><v-icon>mdi-plus-thick</v-icon></v-btn>
    </div>
  </v-list-item>
</template>

<script>
export default {
  props: {
    showRepo: { type: Boolean, default: null },
    value: Object,
    estados: Array,
    drag: Boolean,
    disabled: Boolean,
    noEstado: Boolean,
  },
  data() {
    return {
      openBtn: false,
    };
  },
  computed: {
    unidad: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    estadoActual() {
      return (
        this.unidad.EstadoUnidadesEquipo || {
          icon: "mdi-help",
          color: "grey",
          nombre: "Sin definir",
        }
      );
    },
  },
  methods: {
    cambiarEstado(estado) {
      this.$set(this.unidad, "EstadoUnidadesEquipo", estado);

      this.openBtn = false;
    },
    changeUsed() {
      this.$emit('changeUsed');
    },
		startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("miembro", JSON.stringify(item));
    },
  },
};
</script>

<style>
.cursor-grab {
	cursor: grab;
}

.unidad-disabled {
	position: relative;
}
.unidad-disabled::before {
	content: '';
	background: #5a5a5aaa;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  z-index: 10;
}
.unidad-disabled::after {
	content: '';
	background: #303030;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 95%;
	height: 5px !important;
	min-height: 5px !important;
	max-height: 5px !important;
	transform: translate(-50%,-50%);
  z-index: 10;
}
</style>